/* eslint-disable react-hooks/exhaustive-deps */
import { useQuery } from '@tanstack/react-query'
import moment from 'moment'
import { FC, useEffect, useState } from 'react'
import { dateRangePickerDefault } from '../../../common/helpers/DateHandlers'
import { IFund } from '../../../common/interfaces/fund.interface'
import { SuscriptionStatusEnum } from '../../../common/interfaces/movement.interface'
import { FundService } from '../../../services/FundService'
import { IFilters } from './tabs/SuscriptionTab'

interface IProps {
  filters: IFilters
  setFilters: (state: IFilters, cb?: ((state: IFilters) => void) | undefined) => void
  rescue?: boolean
}

const initialFilters: IFilters = {
  start_date: '',
  end_date: '',
  status: '',
  fund: '',
}

export const Filter: FC<IProps> = ({ filters, setFilters, rescue }) => {
  const [localFilters, setLocalFilters] = useState<IFilters>(initialFilters)

  useEffect(() => {
    setLocalFilters(filters)
    if (filters.end_date === '' && filters.start_date === '') {
      $('input[name="daterange"]').trigger('cancel.daterangepicker')
    }
    // setLocalFilters(initialFilters)
  }, [JSON.stringify(filters)])

  const {
    data: funds,
    isLoading,
    isFetching,
  } = useQuery(['fund-show'], () => FundService().showFunds(), {
    keepPreviousData: true,
  })

  useEffect(() => {
    //@ts-ignore
    $(function () {
      //@ts-ignore
      $('input[name="daterange"]').daterangepicker(dateRangePickerDefault)
      $('input[name="daterange"]').on('apply.daterangepicker', function (ev, picker) {
        $(this).val(
          picker.startDate.format('DD/MM/YYYY') + ' - ' + picker.endDate.format('DD/MM/YYYY')
        )
        setLocalFilters((prevState) => ({
          ...prevState,
          start_date: moment(picker.startDate).format('YYYY-MM-DD HH:mm:ss.SSS'),
          end_date: moment(picker.endDate).format('YYYY-MM-DD HH:mm:ss.SSS'),
        }))
      })

      $('input[name="daterange"]').on('cancel.daterangepicker', function () {
        $(this).val('')
        setLocalFilters((prevState) => ({
          ...prevState,
          start_date: '',
          end_date: '',
        }))
      })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div
        className='modal modal_outer right_modal fade scroll h-400px px-20'
        id='filter-subscriptions'
        role='dialog'
        aria-labelledby='myModalLabel2'
      >
        <div className='modal-dialog' role='document'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h2 className='text-alert mb-0'>Filtros</h2>
              <i className='bi bi-x-lg fs-5 cursor-pointer' data-bs-dismiss='modal'></i>
            </div>
            <div className='modal-body d-flex flex-column gap-10'>
              <div className='form-group'>
                <label className='text-muted fs-6 mb-2'>Seleccione un periodo</label>
                <input
                  className='form-control form-control-solid'
                  style={{ zIndex: 1000000000000 }}
                  type='text'
                  placeholder='Seleccione un periodo'
                  name='daterange'
                />
              </div>
              <div className='form-group d-flex flex-column'>
                <label className='text-muted fs-6 mb-2'>Seleccione un estado</label>
                <select
                  style={{
                    color: 'var(--bs-gray-700)',
                    backgroundColor: 'var(--bs-gray-100)',
                    borderColor: 'var(--bs-gray-100)',
                    paddingRight: 13,
                    paddingLeft: 13,
                    paddingTop: 10,
                    paddingBottom: 10,
                    outline: 'none',
                  }}
                  id=''
                  defaultValue={''}
                  value={localFilters.status || ''}
                  onChange={(e) =>
                    setLocalFilters({ ...localFilters, status: e.target.value as any })
                  }
                >
                  <option value=''>Todos</option>
                  <option value={SuscriptionStatusEnum.INPROGRESS}>En proceso</option>
                  <option value={SuscriptionStatusEnum.APPROVED}>Aprobado</option>
                  {/* <option value={SuscriptionStatusEnum.PENDING}>Pendiente</option> */}
                  {/* <option value={SuscriptionStatusEnum.ANNULLED}>Anulado</option> */}
                </select>
              </div>
              <div className='form-group d-flex flex-column'>
                <label className='text-muted fs-6 mb-2'>Seleccione un fondo</label>
                <select
                  style={{
                    color: 'var(--bs-gray-700)',
                    backgroundColor: 'var(--bs-gray-100)',
                    borderColor: 'var(--bs-gray-100)',
                    paddingRight: 13,
                    paddingLeft: 13,
                    paddingTop: 10,
                    paddingBottom: 10,
                    outline: 'none',
                  }}
                  id=''
                  defaultValue={''}
                  value={localFilters.fund || ''}
                  onChange={(e) =>
                    setLocalFilters({ ...localFilters, fund: e.target.value as any })
                  }
                >
                  {isLoading && isFetching ? (
                    <option value=''>Cargando ...</option>
                  ) : (
                    <>
                      <option value=''>Todos</option>
                      {funds?.map((e: IFund, index: number) => {
                        return (
                          <option key={index} value={e.codFund}>
                            {e.title}
                          </option>
                        )
                      })}
                    </>
                  )}
                </select>
              </div>
            </div>
            <div className='modal-footer py-2'>
              <div className='text-end'>
                <button
                  style={{ backgroundColor: '#eee', color: '#969696' }}
                  type='button'
                  className='btn btn col m-3'
                  data-bs-dismiss='modal'
                >
                  Cancelar
                </button>
                <button
                  style={{
                    background: 'rgb(0, 123, 195)',
                    color: 'white',
                  }}
                  data-bs-dismiss='modal'
                  aria-label='Close'
                  type='button'
                  className='btn btn col m-3 fw'
                  onClick={() => setFilters(localFilters)}
                >
                  Confirmar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
